import React from 'react';

import { Text } from '@schibsted-smb/fireball';
import { BurgerMenuFlat, CloseMenuFlat } from '@schibsted-smb/fireball-icons-prosjektpluss';
import useTranslation from 'next-translate/useTranslation';

import { ToggleMenuButtonWrapper } from '~components/layouts/Header/variants/MainHeader/ToggleMenuButton/style';

interface ToggleMenuButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

export const ToggleMenuButton: React.FC<ToggleMenuButtonProps> = ({ onClick, isOpen }) => {
  const { t } = useTranslation();

  return (
    <ToggleMenuButtonWrapper
      onClick={() => onClick()}
      display={['flex', null, null, null, null, null, 'none']}
      alignItems="center"
      justifyContent={['flex-end', null, null, null, null, null, null, 'none']}
    >
      <Text.p fontSize="14px" fontWeight={600} mb="2px" mr="8px">
        {isOpen ? <>{t('common:header.mobile.closeMenu')}</> : <>{t('common:header.mobile.openMenu')}</>}
      </Text.p>
      {!isOpen ? (
        <BurgerMenuFlat fill="black.black10" width="24px" height="24px" />
      ) : (
        <CloseMenuFlat fill="black.black10" width="24px" height="24px" />
      )}
    </ToggleMenuButtonWrapper>
  );
};
