import React, { SVGProps } from 'react';

import { Text } from '@schibsted-smb/fireball';

import { StyledIcon } from '~components/layouts/Header/variants/MobileMenu/MobileMenuItem/style';

interface MobileMenuLinkProps {
  title: string;
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  iconRotation?: string;
  fontSize?: string;
  fontWeight?: string;
}

export const MobileMenuLink: React.FC<MobileMenuLinkProps> = ({
  title,
  icon: Icon,
  iconRotation,
  fontSize,
  fontWeight,
}) => (
  <>
    <Text.p fontSize={fontSize || '18px'} fontWeight={fontWeight || 600} m={0}>
      {title}
    </Text.p>
    <StyledIcon rotate={iconRotation || '0'}>
      {!!Icon && <Icon height="16px" width="16px" fill="black.black10" />}
    </StyledIcon>
  </>
);
