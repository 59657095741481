import React, { useState, useEffect } from 'react';

import { ButtonLink, Div } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';

import { Link } from '~components/base/Link';
import { LinkDataProps } from '~components/layouts/Header/common/interface';
import { MobileMenuItem } from '~components/layouts/Header/variants/MobileMenu/MobileMenuItem';
import { MobileMenuWrapper } from '~components/layouts/Header/variants/MobileMenu/style';
import { useWindowSize } from '~hooks/useWindowSize';
import { LINKS } from '~utils/links';

interface MobileMenuProps {
  isOpen: boolean;
  data: LinkDataProps[];
  handleClose: () => void;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, data, handleClose }) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(0);
  const desktop = useWindowSize().width > 1440;

  const handleClick = (index: number) => {
    setActiveItem(index);
  };

  useEffect(() => {
    if (isOpen && desktop) {
      handleClose();
    }
  }, [desktop]);

  return (
    <MobileMenuWrapper hidden={!isOpen} isOpen={isOpen}>
      <Div
        display="flex"
        flexDirection="column"
        alignContent="center"
        width={[311, null, 350, 444, 585, 876, null, null, 1374, 1520]}
      >
        {data?.map((item, index) => (
          <MobileMenuItem
            key={`mobilemenu-${item.id}`}
            title={item?.title}
            src={item?.src}
            linkGroup={item?.linkGroup}
            onClick={() => handleClick(index)}
            isOpen={index === activeItem}
            handleClose={handleClose}
          />
        ))}

        <Link href={`${LINKS.REGISTER.PROJECT.START}?location=header`} hasAnchor>
          <ButtonLink
            size="medium"
            variant="primary"
            fontWeight="bold"
            width={1}
            px={6}
            mt={6}
            onClick={handleClose}
            display={['block', null, null, null, 'none']}
          >
            {t('common:header.mobile.registerProject')}
          </ButtonLink>
        </Link>
      </Div>
    </MobileMenuWrapper>
  );
};
