/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { Checkbox, Div } from '@schibsted-smb/fireball';

import { SecondaryInput } from '~components/elements/SecondaryInput';

interface NewsletterFormProps {
  inputLabel: string;
  inputButtonText: string;
  inputCheckboxLabel: string;
  inputSuccessLabel: string;
  inputErrorLabel: string;
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
  inputLabel,
  inputButtonText,
  inputCheckboxLabel,
  inputSuccessLabel,
  inputErrorLabel,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputDisabled, setInputDisabled] = useState(true);
  const [inputLoading, setInputLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [signedUp, isSignedUp] = useState(false);

  const onSubmit = (_event: React.FormEvent) => {
    const handleValidation = (email: string) => {
      // eslint-disable-next-line no-useless-escape
      const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return !!email.match(regexEmail);
    };

    if (handleValidation(inputValue)) {
      setInputLoading(true);
      setTimeout(() => {
        setInputValue('');
        setInputLoading(false);
        setInputError(false);
        setConfirmationText(inputSuccessLabel);
        isSignedUp(true);
      }, 1500);
    } else {
      setInputError(true);
    }
  };

  const handleCheckbox = () => {
    if (inputDisabled) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  };

  return (
    <Div height="73px" width={[1, 1, 1, 1, 1, 1, 0.45]} alignItems="center">
      <form
        method="post"
        target="formSending"
        action="https://cb.sailthru.com/s/6nh/SignedUpNewsletter"
        onSubmit={onSubmit}
      >
        {!signedUp ? (
          <>
            <label htmlFor="emailInput" style={{ width: '100%' }}>
              <SecondaryInput
                inputName="email"
                inputLabel={inputLabel}
                inputValue={inputValue}
                inputButtonText={inputButtonText}
                inputErrorLabel={inputErrorLabel}
                inputError={inputError}
                inputLoading={inputLoading}
                inputDisabled={inputDisabled}
                onChange={(e) => setInputValue(e.target.value)}
                buttonOutside
                boxShadow
                id="emailInput"
              />
            </label>

            <Checkbox
              id="accept-terms-checkbox"
              name="accept-terms-checkbox"
              label={inputCheckboxLabel}
              pt={inputError ? '10px' : '20px'}
              pl={[0, 0, 0, 0, '10px', '10px', '10px']}
              onChange={() => handleCheckbox()}
              checked={!inputDisabled}
            />
          </>
        ) : (
          <Div pt="25px" pl="0px">
            {confirmationText}
          </Div>
        )}
        <iframe title="formSending" name="formSending" style={{ display: 'none' }} />
      </form>
    </Div>
  );
};
