import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface StyledLinkWrapperProps {
  isActive: boolean;
}

export const StyledIcon = styled(Div)`
  transform: rotate(90deg);
`;

export const StyledLinkWrapper = styled(Div)<StyledLinkWrapperProps>`
  color: ${(props) => (props.isActive ? '#273245' : '#68707D')};
  cursor: pointer;
  :hover {
    color: #273245;
  }
`;
