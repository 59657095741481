/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';

import { Div } from '@schibsted-smb/fireball';
import { useRouter } from 'next/router';

import { SecondaryInput } from '~components/elements/SecondaryInput';
import { useWindowSize } from '~hooks/useWindowSize';
import { LINKS } from '~utils/links';

interface SearchInputProps {
  inputLabel?: string;
  inputButtonText?: string;
  inputErrorLabel?: string;
  buttonOutside?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  inputLabel,
  inputButtonText,
  inputErrorLabel,
  buttonOutside,
}) => {
  const router = useRouter();
  const [input, setInput] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setInput(event.target.value);
  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    await router.push(`${LINKS.REGISTER.BUSINESS.START}?companyName=${input}`);
  };

  const disabled = useMemo(() => input.length === 0, [input]);

  return (
    <Div alignItems="center">
      <form onSubmit={handleSubmit}>
        <label htmlFor={inputLabel} style={{ width: '100%' }}>
          <SecondaryInput
            id={inputLabel}
            inputName="searchInput"
            inputLabel={inputLabel}
            inputValue={input}
            inputButtonText={inputButtonText}
            inputErrorLabel={inputErrorLabel}
            onChange={handleChange}
            inputLoading={false}
            inputDisabled={disabled}
            buttonOutside={buttonOutside}
            onClick={handleSubmit}
            boxShadow={useWindowSize().width > 415}
          />
        </label>
      </form>
    </Div>
  );
};
