import styled from 'styled-components';

export const MobileMenuWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 9;
  right: 0;
  top: 77px;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  padding: 16px 16px 32px 16px;
  display: flex;
  justify-content: center;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;
