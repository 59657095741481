import React, { isValidElement } from 'react';

import NextLink from 'next/link';

import { LinkProps } from './interface';
import { StyledLink } from './style';

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  as,
  href,
  hasAnchor = false,
  noUnderline = false,
  newTab,
  anchorProps,
  shallow = false,
  fontWeight,
  fontSize,
  icon,
  borderBottom,
  prefetch,
  display,
  ...props
}) => {
  let isInternal = true;
  const actualAs = as;
  const actualHref = href;

  // Handle external links (from CMS)
  if (actualHref.startsWith('https://') || actualHref.startsWith('//')) isInternal = false;

  if (isInternal && !newTab) {
    if (hasAnchor) {
      return (
        <NextLink href={actualHref} passHref as={actualAs} shallow={shallow} prefetch={prefetch} legacyBehavior>
          {children}
        </NextLink>
      );
    }

    return (
      <NextLink href={actualHref} passHref as={actualAs} shallow={shallow} prefetch={prefetch} legacyBehavior>
        <StyledLink
          noUnderline={noUnderline}
          borderBtm={borderBottom}
          fontSize={fontSize}
          target={newTab ? '_blank' : undefined}
          {...anchorProps}
          {...props}
          fontWeight={fontWeight || 'normal'}
          display={display}
        >
          {children}
        </StyledLink>
      </NextLink>
    );
  }

  if (hasAnchor) {
    const childrenWithProps = React.Children.map(children, (child) => {
      if (isValidElement(child)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return React.cloneElement(child, { href });
      }
      return child;
    });
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{childrenWithProps}</>;
  }

  // External links
  return (
    <StyledLink
      noUnderline={noUnderline}
      href={actualHref}
      target="_blank"
      rel="noopener noreferrer"
      {...anchorProps}
      {...props}
    >
      {children}
    </StyledLink>
  );
};
