import React from 'react';

import { Button, Input } from '@schibsted-smb/fireball';

import { InputWrapper, InputContainer, ButtonWrapper } from '~components/elements/SecondaryInput/style';

interface SecondaryInputProps {
  inputName: string;
  inputLabel: string;
  inputValue: string;
  inputButtonText: string;
  inputErrorLabel?: string;
  inputLoading: boolean;
  inputDisabled?: boolean;
  inputError?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void | Promise<void>;
  buttonOutside?: boolean;
  boxShadow?: boolean;
  onFocus?: () => void;
  id?: string;
}

export const SecondaryInput: React.FC<SecondaryInputProps> = ({
  id,
  boxShadow,
  inputName,
  inputLabel,
  inputValue,
  inputButtonText,
  inputErrorLabel,
  inputLoading,
  inputDisabled,
  inputError,
  onChange,
  onClick,
  onFocus,
  buttonOutside,
}) => (
  <InputWrapper>
    <InputContainer shadow={boxShadow}>
      <Input
        id={id}
        name={inputName}
        label={inputLabel}
        value={inputValue}
        onChange={onChange}
        containersProps={{ width: '100%' }}
        error={inputError ? { msg: inputErrorLabel, variant: 'danger' } : null}
        borderRadius="3"
        autocomplete="off"
        scale="large"
        onFocus={onFocus}
      />
    </InputContainer>
    <ButtonWrapper buttonOutside={buttonOutside}>
      <Button
        size="large"
        fontWeight={600}
        type="submit"
        isLoading={inputLoading}
        disabled={inputDisabled || null}
        height="52px"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onClick}
      >
        {inputButtonText}
      </Button>
    </ButtonWrapper>
  </InputWrapper>
);
