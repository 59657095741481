import { Div } from '@schibsted-smb/fireball';
import styled, { css } from 'styled-components';

interface Props {
  buttonOutside?: boolean;
  shadow?: boolean;
}

export const InputWrapper = styled(Div)<Props>`
  position: relative;
`;
export const InputContainer = styled(Div)<Props>`
  position: relative;
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 18px 20px -20px rgba(0, 0, 0, 0.15), 0px 30px 60px -20px rgba(50, 50, 93, 0.1);
    `};
`;

export const ButtonWrapper = styled(Div)<Props>`
  position: absolute;
  top: 11px;
  right: 11px;
  @media (max-width: 992px) {
    ${(props) =>
      props.buttonOutside &&
      css`
        position: relative;
        padding-top: 10px;
        padding-left: 10px;
      `};
  }
`;
