import React, { useEffect, useState } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { CalChevronRightFlat } from '@schibsted-smb/fireball-icons-common';
import { useRouter } from 'next/router';

import { Link } from '~components/base/Link';
import { LinkGroupProps } from '~components/layouts/Header/common/interface';
import { HeaderLinkGroup } from '~components/layouts/Header/variants/MainHeader/HeaderLinkListItem/HeaderLinkGroup';
import { StyledIcon, StyledLinkWrapper } from '~components/layouts/Header/variants/MainHeader/HeaderLinkListItem/style';

interface HeaderLinkListItemProps {
  title: string;
  src: string;
  group: LinkGroupProps[];
}

export const HeaderLinkListItem: React.FC<HeaderLinkListItemProps> = ({ title, src, group }) => {
  const { asPath } = useRouter();

  const [isOpen, setOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [activeGroup, setActiveGroup] = useState(false);

  useEffect(() => {
    setCurrentPage(asPath);
    if (asPath.startsWith(src)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [asPath]);

  useEffect(() => {
    setActiveGroup(group?.some((e) => e.src === currentPage) || false);
  }, [currentPage]);

  return (
    <Div
      display="flex"
      alignItems="center"
      position="relative"
      mr={['24px', null, null, null, null, null, null, null, '64px']}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(true)}
      height="77px"
    >
      {src ? (
        <Link href={src} noUnderline prefetch={false}>
          <StyledLinkWrapper isActive={isActive}>
            <Text.p fontWeight={600} fontSize="16px" p={0} m={0}>
              {title}
            </Text.p>
          </StyledLinkWrapper>
        </Link>
      ) : (
        <StyledLinkWrapper isActive={isOpen || activeGroup}>
          <Text.p fontWeight={600} fontSize="16px" p={0} m={0}>
            {title}
          </Text.p>
        </StyledLinkWrapper>
      )}

      {group && (
        <StyledIcon pl="2px" ml="8px">
          <CalChevronRightFlat height="12px" width="12px" fill={isOpen ? 'primary.base' : 'black.black7'} />
        </StyledIcon>
      )}

      {group && <HeaderLinkGroup data={group} isOpen={isOpen} currentPage={currentPage} />}
    </Div>
  );
};
