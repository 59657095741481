import React from 'react';

import { Div, Image } from '@schibsted-smb/fireball';

import { Link } from '~components/base/Link';
import { sFontSize } from '~components/base/Paragraph';
import { Title } from '~components/base/Title';
import { List, ListItem } from '~components/layouts/Footer/style';

interface LinkListProps {
  title: string;
  data: Array<LinkTypes>;
  variant: 'list' | 'iconList';
}

interface LinkTypes {
  title: string;
  link: string;
  icon?: string;
}

export const LinkList: React.FC<LinkListProps> = ({ title, data, variant }) => (
  <Div mb="32px" width={[1, null, null, 0.4, null, 0.2]}>
    <Title.H4 mb="24px" fontSize="18px">
      {title}
    </Title.H4>
    <List>
      {data.map((item) => (
        <ListItem key={item.link} pb={5}>
          {variant === 'iconList' && (
            <Image src={item.icon} width="16px" height="16px" mr={6} alt={`${item.title} logo`} />
          )}
          <Link title={item.title} href={item.link} anchorProps={{ fontWeight: 400, fontSize: sFontSize }}>
            {item.title}
          </Link>
        </ListItem>
      ))}
    </List>
  </Div>
);
