import { Link as FBLink, useColor } from '@schibsted-smb/fireball';
import styled from 'styled-components';
import { space, layout, border, color, flexbox, position, typography } from 'styled-system';

import { StyledLinkProps } from './interface';

export const StyledLink = styled(FBLink)<StyledLinkProps>`
  color: inherit;
  text-decoration: ${({ noUnderline }): string | null => (noUnderline ? 'none !important' : 'none')};
  border-bottom: ${({ borderBtm }) => (borderBtm ? `2px solid ${useColor('primary.base')}` : 'none')};
  padding-bottom: ${({ borderBtm }) => (borderBtm ? '2px' : '0px')};
  white-space: unset;
  display: inline;
  font-size: inherit;
  cursor: pointer;
  ${space}
  ${layout}
  ${border}
  ${color}
  ${flexbox}
  ${position}
  ${typography}
  &:hover {
    color: inherit;
    text-decoration: ${({ noUnderline }): string | null => (noUnderline ? 'none' : null)};
  }
`;
