import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface StyledLinkGroupProps {
  isOpen: boolean;
}

export const StyledLinkGroup = styled(Div)<StyledLinkGroupProps>`
  position: absolute;
  z-index: 10;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 28px 25px 20px 25px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  margin-left: -40px;
  top: 64px;
  left: 0;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const StyledLinkGroupItem = styled(Div).attrs(() => ({ as: 'a' }))`
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  column-gap: 16px;
  padding: 16px 16px 16px 16px;
  transition: 0.2s ease;
  border-radius: 3px;
  margin-bottom: 8px;

  :hover {
    background-color: #edf6f6;
    transition: 0.2s ease;
  }
`;
