import React, { useState } from 'react';

import { Div, ButtonLink } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';

import Logo from '~assets/logo/logo.svg';
import { MaxlLogo } from '~assets/logo/MaxlLogo';
import { Link } from '~components/base/Link';
import { headerLinks } from '~components/layouts/Header/common/headerLinks';
import { HeaderBody } from '~components/layouts/Header/style';
import { HeaderLinkListItem } from '~components/layouts/Header/variants/MainHeader/HeaderLinkListItem';
import { ToggleMenuButton } from '~components/layouts/Header/variants/MainHeader/ToggleMenuButton';
import { MobileMenu } from '~components/layouts/Header/variants/MobileMenu';
import { LINKS } from '~utils/links';

interface IHeaderProps {
  hideMenu?: boolean;
  isMaxlEnabled?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ hideMenu, isMaxlEnabled }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <HeaderBody>
        <Div
          display="flex"
          justifyContent={isMaxlEnabled ? 'center' : 'space-between'}
          alignItems="center"
          width={[450, null, 350, 444, 585, 876, 1072, 1198, 1374, 1520]}
          px="10px"
          backgroundColor="#fff"
        >
          {!isMaxlEnabled ? (
            <Link href={LINKS.HOME} aria-label="Forside" title="PROSJEKT+">
              <Div maxWidth={['150px', null, null, null, null, null, null, '200px']} width="100%">
                <Logo width="100%" />
              </Div>
            </Link>
          ) : (
            <Link href="https://mittanbud.no" aria-label="Forside" title="Mittanbud XL">
              <Div mx="auto" maxWidth={['200px']} width="100%">
                <MaxlLogo width="200" height="34" />
              </Div>
            </Link>
          )}

          {!hideMenu && (
            <>
              <Div display={['none', null, null, null, null, null, 'flex']} justifyContent="space-between">
                {headerLinks?.map((item) => (
                  <HeaderLinkListItem key={item?.title} title={item?.title} src={item?.src} group={item?.linkGroup} />
                ))}
              </Div>
              <Div display="flex" justifyContent="center">
                <Link href={`${LINKS.REGISTER.PROJECT.START}?location=header`} hasAnchor>
                  <ButtonLink
                    size="small"
                    variant="primary"
                    fontWeight="bold"
                    px="24px"
                    mr={[0, 6, null, null, null, null, 0]}
                    display={['none', null, null, null, 'flex']}
                  >
                    {t('common:header.desktop.registerProject')}
                  </ButtonLink>
                </Link>

                <ToggleMenuButton onClick={toggleIsOpen} isOpen={isOpen} />
              </Div>
            </>
          )}
        </Div>
      </HeaderBody>
      <MobileMenu isOpen={isOpen} data={headerLinks} handleClose={toggleIsOpen} />
    </>
  );
};
