import React from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { CalChevronRightFlat } from '@schibsted-smb/fireball-icons-common';
import { motion } from 'framer-motion';

import { Link } from '~components/base/Link';
import { animateGroup } from '~components/layouts/Header/common/headerAnimations';
import { LinkGroupProps } from '~components/layouts/Header/common/interface';
import {
  StyledLinkGroup,
  StyledLinkGroupItem,
} from '~components/layouts/Header/variants/MainHeader/HeaderLinkListItem/HeaderLinkGroup/style';

interface HeaderLinkGroupProps {
  data?: LinkGroupProps[];
  isOpen: boolean;
  currentPage: string;
}

export const HeaderLinkGroup: React.FC<HeaderLinkGroupProps> = ({ data, isOpen, currentPage }) => (
  <motion.div initial="exit" animate={isOpen ? 'enter' : 'exit'} variants={animateGroup} hidden={!isOpen}>
    <StyledLinkGroup width="358px" isOpen={isOpen}>
      {data?.map((item) => (
        <Link href={item.src} key={item.title} hasAnchor noUnderline>
          <StyledLinkGroupItem backgroundColor={currentPage === item.src ? 'primary.lighter' : '#FFFFFF'}>
            <Div>
              <Text.p fontWeight={currentPage === item.src ? 600 : 'regular'}>{item.title}</Text.p>
              <Text.p fontSize="12px">{item.description}</Text.p>
            </Div>
            <CalChevronRightFlat height="16px" width="16px" fill="black.black10" />
          </StyledLinkGroupItem>
        </Link>
      ))}
    </StyledLinkGroup>
  </motion.div>
);
