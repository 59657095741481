import { useColor, defaultCompose, DefaultComposeProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0.8rem;
`;

export const ListItem = styled.li<DefaultComposeProps>`
  line-height: 1.4;
  list-style: none;
  ${defaultCompose};

  a {
    color: ${() => useColor('black.black9')};
    text-decoration: none;

    &:hover {
      color: ${() => useColor('black.black9')};
      text-decoration: underline;
    }
  }
`;
