import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface CommonProps {
  isOpen?: boolean;
}

export const HeaderBody = styled(Div).attrs(() => ({ as: 'header' }))<CommonProps>`
  display: flex;
  height: 77px;
  position: sticky;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black.black0};
  top: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
