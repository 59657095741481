import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface StyledIconProps {
  rotate?: string;
}

export const MobileMenuItemWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 8px;

  :hover {
    background-color: #edf6f6;
  }
`;

export const StyledIcon = styled(Div)<StyledIconProps>`
  display: flex;
  align-content: center;
  transform: ${({ rotate }) => (rotate !== null ? `rotate(${rotate}deg)` : 'rotate(90deg)')};
`;
