import React from 'react';

import { Div } from '@schibsted-smb/fireball';

import { Paragraph } from '~components/base/Paragraph';
import { Title } from '~components/base/Title';
import { NewsletterForm } from '~components/elements/Newsletter/NewsletterForm';

interface NewsletterProps {
  title: string;
  description: string;
  inputLabel: string;
  inputButtonText: string;
  inputCheckboxLabel: string;
  inputSuccessLabel: string;
  inputErrorLabel: string;
}

export const Newsletter: React.FC<NewsletterProps> = ({
  title,
  description,
  inputLabel,
  inputButtonText,
  inputCheckboxLabel,
  inputSuccessLabel,
  inputErrorLabel,
}) => (
  <Div
    px="10px"
    pt={['96px', '96px', '96px', '96px', '96px', '96px', '104px', '136px', '152px', '200px', '200px']}
    pb="100px"
    width="100%"
  >
    <Title.H3
      fontSize={['32px', '32px', '32px', '40px', '40px', '40px', '40px']}
      width={[1, 1, 1, 1, 1, 1, 0.45, 0.45, 0.35, 0.45, 0.45]}
      mb="40px"
      fontWeight="bold"
    >
      {title}
    </Title.H3>
    <Div
      display="flex"
      justifyContent="space-between"
      flexDirection={['column', 'column', 'column', 'column', 'column', 'column', 'row']}
    >
      <Paragraph width={[1, 1, 1, 1, 1, 1, 0.45]} mb={[7, 7, 7, 7, 7, 7, 0]} mt="32px">
        {description}
      </Paragraph>
      <NewsletterForm
        inputLabel={inputLabel}
        inputButtonText={inputButtonText}
        inputCheckboxLabel={inputCheckboxLabel}
        inputSuccessLabel={inputSuccessLabel}
        inputErrorLabel={inputErrorLabel}
      />
    </Div>
  </Div>
);
