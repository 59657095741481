import React, { useEffect, useState } from 'react';

import { Div } from '@schibsted-smb/fireball';
import { CalChevronRightFlat } from '@schibsted-smb/fireball-icons-common';
import { ArrowRightFlat } from '@schibsted-smb/fireball-icons-prosjektpluss';
import { useRouter } from 'next/router';

import { Link } from '~components/base/Link';
import { LinkDataProps } from '~components/layouts/Header/common/interface';
import { MobileMenuLink } from '~components/layouts/Header/variants/MobileMenu/MobileMenuItem/MobileMenuLink';
import { MobileMenuItemWrapper } from '~components/layouts/Header/variants/MobileMenu/MobileMenuItem/style';

import { MobileMenuWrapper } from './MobileMenuWrapper';

interface MobileMenuItemProps extends LinkDataProps {
  onClick?: () => void;
  isOpen?: boolean;
  handleClose?: () => void;
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  title,
  src,
  linkGroup,
  onClick,
  isOpen,
  handleClose,
}) => {
  const { asPath } = useRouter();
  const [isClicked, setClicked] = useState(false);
  const [isActive, setActive] = useState(false);
  const [currentPage, setCurrentPage] = useState('');

  const handleClick = () => {
    onClick();
    if (isClicked && isOpen) {
      setClicked(false);
    } else {
      setClicked(true);
    }
  };

  useEffect(() => {
    setCurrentPage(asPath);
    if (asPath.startsWith(src)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [asPath]);

  return (
    <Div width="100%" overflow="hidden">
      {src ? (
        <Link href={src} noUnderline>
          <MobileMenuItemWrapper
            p="16px"
            onClick={() => handleClose()}
            backgroundColor={isActive ? 'primary.lighter' : '#FFFFFF'}
          >
            <MobileMenuLink title={title} icon={ArrowRightFlat} />
          </MobileMenuItemWrapper>
        </Link>
      ) : (
        <>
          <MobileMenuItemWrapper p="16px" onClick={handleClick}>
            <MobileMenuLink
              title={title}
              icon={CalChevronRightFlat}
              iconRotation={isOpen && isClicked ? '270' : '90'}
            />
          </MobileMenuItemWrapper>

          {linkGroup && (
            <MobileMenuWrapper hidden={!isOpen || !isClicked} isOpen={isClicked}>
              {linkGroup?.map((item) => (
                <Link key={`subgroup-${item.title}`} href={item.src} noUnderline>
                  <MobileMenuItemWrapper
                    key={`linkgroup-${item.title}`}
                    pl="32px"
                    pr="16px"
                    pt="16px"
                    pb="16px"
                    onClick={handleClose}
                    backgroundColor={currentPage === item.src ? 'primary.lighter' : '#FFFFFF'}
                  >
                    <MobileMenuLink
                      title={item.title}
                      fontWeight={currentPage === item.src ? 'bold' : 'regular'}
                      icon={ArrowRightFlat}
                      fontSize="16px"
                    />
                  </MobileMenuItemWrapper>
                </Link>
              ))}
            </MobileMenuWrapper>
          )}
        </>
      )}
    </Div>
  );
};
