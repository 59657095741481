import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { BusinessSearch } from '~components/elements/BusinessSearch';
import { Newsletter } from '~components/elements/Newsletter';
import { SectionWrapper } from '~components/elements/SectionWapper';
import { LinkList } from '~components/layouts/Footer/LinkList';
import { LINKS } from '~utils/links';

interface FooterProps {
  hideSignup?: boolean;
}

const followUsLinks = [
  { title: 'Facebook', link: LINKS.SOCIAL.FACEBOOK, icon: '/static/icons/facebook.svg' },
  { title: 'Instagram', link: LINKS.SOCIAL.INSTAGRAM, icon: '/static/icons/instagram.svg' },
  {
    title: 'LinkedIn',
    link: LINKS.SOCIAL.LINKEDIN,
    icon: '/static/icons/linkedin.svg',
  },
  {
    title: 'YouTube',
    link: LINKS.SOCIAL.YOUTUBE,
    icon: '/static/icons/youtube.svg',
  },
];

export const Footer: React.FC<FooterProps> = ({ hideSignup }) => {
  const { t } = useTranslation();
  const { pathname, asPath } = useRouter();

  const isBusiness = pathname === LINKS.FOR_BUSINESSES || pathname === LINKS.TERMS.BUSINESS;
  const isBusinessProfile = asPath.startsWith(LINKS.BUSINESS_PROFILE);

  const companyLinks = [
    { title: `${t('common:footer.company.about')}`, link: LINKS.ABOUT },
    { title: `${t('common:footer.company.contact')}`, link: LINKS.CONTACT },
    { title: `${t('common:footer.company.quality')}`, link: LINKS.QUALITY_ASSURANCE },
    { title: `${t('common:footer.consumer.privacy')}`, link: LINKS.PRIVACY },
  ];

  const businessLinks = [
    { title: `${t('common:footer.business.register')}`, link: LINKS.REGISTER.BUSINESS.START },
    { title: `${t('common:footer.business.businessTerms')}`, link: LINKS.TERMS.BUSINESS },
  ];

  const consumerLinks = [
    { title: `${t('common:footer.consumer.projects')}`, link: LINKS.PROJECT_GUIDES },
    { title: `${t('common:footer.consumer.register')}`, link: LINKS.REGISTER.PROJECT.START },
    { title: `${t('common:footer.consumer.association')}`, link: LINKS.ASSOCIATION },
    { title: `${t('common:footer.consumer.userTerms')}`, link: LINKS.TERMS.CONSUMER },
  ];

  return (
    <SectionWrapper hasContainer divPaddingTop="0px" containerPaddingX="10px">
      {!hideSignup && !isBusinessProfile && (
        <Div mb="72px">
          {isBusiness ? (
            <BusinessSearch
              title="Registrer bedriften din - Start her"
              inputLabel="Skriv navnet på din bedrift"
              inputButtonText="Start"
              inputErrorLabel={t('common:footer.newsletter.inputErrorLabel')}
            />
          ) : (
            <Newsletter
              title={t('common:footer.newsletter.title')}
              description={t('common:footer.newsletter.description')}
              inputLabel={t('common:footer.newsletter.inputLabel')}
              inputButtonText={t('common:footer.newsletter.inputButtonText')}
              inputCheckboxLabel={t('common:footer.newsletter.inputCheckboxLabel')}
              inputSuccessLabel={t('common:footer.newsletter.inputSuccessLabel')}
              inputErrorLabel={t('common:footer.newsletter.inputErrorLabel')}
            />
          )}
        </Div>
      )}

      <Div
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        flexWrap="wrap"
        pt="80px"
        px={['0px', '0px', '0px', '16px', '48px', '32px', '48px', '48px', '64px', '128px', '128px']}
        borderTop="1px solid"
        borderColor="black.black3"
      >
        <LinkList title={t('common:footer.company.title')} data={companyLinks} variant="list" />
        <LinkList title={t('common:footer.consumer.title')} data={consumerLinks} variant="list" />
        <LinkList title={t('common:footer.business.title')} data={businessLinks} variant="list" />
        <LinkList title={t('common:footer.followUs.title')} data={followUsLinks} variant="iconList" />
      </Div>
      <Div fontSize={2} color="black.black9" textAlign="center" my="32px">
        &#169; Schibsted Norge SMB, {new Date().getFullYear()}
      </Div>
    </SectionWrapper>
  );
};
