import React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

interface SeoProps {
  title?: string | null;
  description?: string | null;
  canonical?: string | null;
  image?: string | null;
  noIndex?: boolean;
  created?: string | null;
  updated?: string | null;
  contentType?: 'website' | 'article' | 'profile' | null;
  shouldIndex?: boolean;
}

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',

  // Site name: https://developers.google.com/search/docs/appearance/site-names
  name: 'Prosjekt+',
  // alternateName: 'P+' // Not sure if we want to use this one.

  url: 'https://prosjektpluss.no',
};

export const SEO: React.FC<SeoProps> = ({
  title,
  description,
  canonical,
  image,
  noIndex,
  created,
  updated,
  contentType,
  shouldIndex = true,
}) => {
  const router = useRouter();

  // Title
  const titleFallback = 'PROSJEKT+ | Få riktig håndverker til ditt prosjekt';
  const titleSuffix = ' | PROSJEKT+';
  const currentTitle = title ? `${title}${titleSuffix}` : titleFallback;

  // Description
  const descriptionFallback =
    'Registrer ditt prosjekt, og vi setter deg i kontakt med tre relevante bedrifter for akkurat ditt prosjekt.';
  const currentDescription = description || descriptionFallback;

  // URLs
  const currentCanonical = canonical || `https://prosjektpluss.no${router.asPath}`;
  const currentPageUrl = `https://prosjektpluss.no${router.asPath}`;

  // Images
  const currentImage = image || '/assets/images/seo-fallback.jpg';

  // Dates
  const currentCreateDate = created ? new Date(created).toISOString() : undefined;
  const currentUpdateDate = updated ? new Date(updated).toISOString() : undefined;

  // Content type
  const currentContentType = contentType || 'website';

  // Handle indexation of site (for crawling purposes) - if SHOULD_INDEX = true && shouldIndex = true index page, if not don't.
  const shouldIndexPage =
    process.env.NEXT_PUBLIC_SHOULD_INDEX === 'true' && shouldIndex && !noIndex ? 'index' : 'noindex';

  return (
    <Head>
      {/* Dynamic title tags */}
      <title>{currentTitle}</title>
      <meta property="og:title" content={currentTitle} />
      <meta name="twitter:title" content={currentTitle} />

      {/* Dynamic description tags */}
      <meta name="description" content={currentDescription} />
      <meta property="og:description" content={currentDescription} />
      <meta name="twitter:description" content={currentDescription} />

      {/* Canonical */}
      <link rel="canonical" href={currentCanonical} />

      <meta name="robots" content={shouldIndexPage} />

      {/* OpenGraph */}
      <meta property="og:locale" content="nb" />
      <meta property="og:url" content={currentPageUrl} />
      <meta property="og:site_name" content="PROSJEKT+ | Få riktig håndverker til ditt prosjekt" />
      <meta property="og:image" content={currentImage} />
      <meta property="og:image:secure_url" content={currentImage} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="https://prosjektpluss.no" />
      <meta name="twitter:url" content={currentPageUrl} />
      <meta name="twitter:image" content={currentImage} />
      <meta name="twitter:creator" content="Schibsted Norge SMB AS" />
      <meta property="article:publisher" content="https://www.facebook.com/prosjektpluss" />

      {/* Created and updated */}
      {currentCreateDate && <meta property="article:modified_time" content={currentCreateDate} />}
      {currentUpdateDate && <meta property="article:published_time" content={currentUpdateDate} />}

      {/* Content type */}
      <meta property="og:type" content={currentContentType} />

      {/* JSON-LD/Structured data */}
      {/* TODO: Copy the rest of tooling around this from mittanbud, or move it to Fireball */}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />

      {/* Other statics tags */}
      <link href="https://prosjektpluss.no" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="icon" href="/assets/favicons/favicon.ico" />
      <link rel="apple-touch-icon" sizes="57x57" href="/assets/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="70x70" href="/assets/favicons/mstile-70x70.png" />
      <link rel="icon" type="image/png" sizes="144x144" href="/assets/favicons/mstile-144x144.png" />
      <link rel="icon" type="image/png" sizes="150x150" href="/assets/favicons/mstile-150x150.png" />
      <link rel="icon" type="image/png" sizes="310x150" href="/assets/favicons/mstile-310x150.png" />
      <link rel="icon" type="image/png" sizes="310x310" href="/assets/favicons/mstile-310x310.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/assets/favicons/android-chrome-192x192.png" />
      <link rel="icon" type="image/png" sizes="512x512" href="/assets/favicons/android-chrome-512x512.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileImage" content="/assets/favicons/ms-icon-144x144.png" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5, shrink-to-fit=no" />
    </Head>
  );
};
