import React from 'react';

import { Div } from '@schibsted-smb/fireball';

import { xxlFontSize } from '~components/base/Paragraph';
import { Title } from '~components/base/Title';
import { SearchInput } from '~components/elements/BusinessSearch/SearchInput';
import { useWindowSize } from '~hooks/useWindowSize';

interface NewsletterProps {
  title: string;
  inputLabel: string;
  inputButtonText: string;
  inputErrorLabel: string;
}

export const BusinessSearch = ({ title, inputLabel, inputButtonText, inputErrorLabel }: NewsletterProps) => {
  const isSmall = useWindowSize().width < 730;

  return (
    <Div
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={['column', 'column', 'column', 'column', 'column', 'column', 'row', 'row', 'row', 'row', 'row']}
      px="10px"
      paddingBottom={50}
      width="100%"
      pt={['96px', '96px', '96px', '96px', '96px', '96px', '104px', '136px', '152px', '200px', '200px']}
    >
      <Title.H3 fontSize={xxlFontSize} width={[1, 1, 1, 1, 1, 1, 0.45, 0.45, 0.35, 0.45, 0.45]} mb="32px">
        {title}
      </Title.H3>

      <Div width={[1, 1, 1, 1, 1, 1, 0.45, 0.45, 0.45, 0.45, 0.45]} p={0} position="relative">
        <SearchInput
          inputLabel={inputLabel}
          inputButtonText={inputButtonText}
          inputErrorLabel={inputErrorLabel}
          buttonOutside={isSmall}
        />
      </Div>
    </Div>
  );
};
