import { LinkDataProps } from '~components/layouts/Header/common/interface';
import { LINKS } from '~utils/links';

// Links displayed in the header. Add linkGroup array to display dropdown.
export const headerLinks: LinkDataProps[] = [
  {
    id: 1,
    title: 'For forbruker',
    linkGroup: [
      {
        title: 'Aktuelt',
        src: LINKS.ARTICLES,
        description: 'Nyttige veiledere for deg som skal i gang med et stort oppussings- eller byggeprosjekt.',
      },
      {
        title: 'Referanser',
        src: LINKS.REFERENCES,
        description: 'Sjekk hva bedriftene har utført for andre som har benyttet seg av PROSJEKT+',
      },
      {
        title: 'Registrer prosjektet ditt',
        src: LINKS.REGISTER.PROJECT.START,
        description: 'Kom i gang med byggeprosjektet!',
      },
    ],
  },
  {
    id: 2,
    title: 'For borettslag',
    src: LINKS.ASSOCIATION,
  },
  {
    id: 3,
    title: 'For bedrifter',
    linkGroup: [
      {
        title: 'Register bedrift',
        src: LINKS.FOR_BUSINESSES,
        description: 'Informasjon om hvordan du kan registrere din bedrift hos oss',
      },
      {
        title: 'Aktuelt',
        src: LINKS.ARTICLES,
        description: 'Nyttige veiledere for deg som skal i gang med et stort oppussings- eller byggeprosjekt.',
      },
      {
        title: 'Referanser',
        src: LINKS.REFERENCES,
        description: 'Sjekk hva bedriftene har utført for andre som har benyttet seg av PROSJEKT+',
      },
      {
        title: 'Samarbeidspartnere',
        src: LINKS.PARTNERS,
        description: 'Se liste over våre samarbeidspartnere',
      },
      {
        title: 'Webinar',
        src: LINKS.WEBINAR,
        description: 'Se Svenn sitt Webinar om hvordan vinne flere store prosjekt',
      },
    ],
  },
  {
    id: 4,
    title: 'Veiledere',
    linkGroup: [
      {
        title: 'Prosjektveiledere',
        src: LINKS.PROJECT_GUIDES,
        description: 'Veiledere med alt du trenger å vite om ditt neste store prosjekt!',
      },
      {
        title: 'Prosjektstyring',
        src: LINKS.PROJECT_HANDLING,
        description: 'Alt du trenger for en vellykket gjennomføring av prosjektet ditt',
      },
      {
        title: 'Arkitekt',
        src: LINKS.ARCHITECT,
        description: 'Alt du trenger å vite om det å bruke en arkitekt for renoverings- og byggeprosjekter',
      },
      {
        title: 'Energi og bærekraft',
        src: LINKS.SUSTAINABILITY,
        description: 'Les våre veiledere om energi og bærekraft i byggeprosjekter',
      },
    ],
  },
  {
    id: 5,
    title: 'Om oss',
    linkGroup: [
      {
        title: 'Hvem er vi',
        src: LINKS.ABOUT,
        description: 'Hva er PROSJEKT+ og hva kan vi hjelpe deg med?',
      },
      {
        title: 'Kontakt',
        src: LINKS.CONTACT,
        description: 'Finn informasjon om hvordan du kan kontakte oss',
      },
      {
        title: 'Kvalitetssikring',
        src: LINKS.QUALITY_ASSURANCE,
        description: 'Les om hvordan vi sikrer kvaliteten og trygg gjennomføring av prosjekter',
      },
    ],
  },
];
