import React from 'react';

import { DefaultComposeProps, Div, DivProps, Container } from '@schibsted-smb/fireball';

interface SectionWrapperProps {
  divPaddingTop?: DivProps['pt'];
  divPaddingBottom?: DivProps['pb'];
  divPaddingX?: DivProps['px'];
  divPaddingY?: DivProps['py'];
  divPaddingLeft?: DivProps['pl'];
  divPaddingRight?: DivProps['pr'];
  backgroundColor?: DivProps['backgroundColor'];
  hasContainer?: boolean;
  containerMaxWidth?: DefaultComposeProps['maxWidth'];
  containerPaddingX?: DefaultComposeProps['px'];
  containerPaddingBottom?: DefaultComposeProps['paddingBottom'];
  hasBackgroundBox?: boolean;
  backgroundBox?: React.ReactElement;
  sectionWidth?: DefaultComposeProps['width'];
  sectionHeight?: DefaultComposeProps['height'];
  containerHeight?: DefaultComposeProps['height'];
  sticky?: boolean;
  hasTopShadow?: boolean;
}

export const SectionWrapper: React.FC<React.PropsWithChildren<SectionWrapperProps>> = ({
  children,
  divPaddingTop,
  divPaddingBottom,
  backgroundColor,
  divPaddingX,
  divPaddingY,
  divPaddingRight,
  divPaddingLeft,
  hasContainer = false,
  containerMaxWidth,
  containerPaddingX,
  containerPaddingBottom,
  hasBackgroundBox,
  backgroundBox,
  sectionWidth,
  sectionHeight,
  containerHeight,
  sticky,
  hasTopShadow,
}) => (
  <>
    {hasBackgroundBox && backgroundBox && { backgroundBox }}
    <Div
      style={sticky ? { position: 'sticky', bottom: 0, zIndex: 1, width: '100%' } : undefined}
      as="section"
      pt={divPaddingTop || ['96px', null, null, null, null, null, '104px', '136px', '152px', '200px']}
      pb={divPaddingBottom || '0px'}
      pr={divPaddingRight}
      pl={divPaddingLeft}
      px={divPaddingX || null}
      py={divPaddingY || null}
      backgroundColor={backgroundColor}
      width={sectionWidth || null}
      height={sectionHeight || null}
      boxShadow={hasTopShadow ? '0px -4px 7px 0px rgba(16, 31, 65, 0.07);' : null}
    >
      {hasContainer ? (
        <Container
          maxWidth={containerMaxWidth || [311, null, 350, 444, 585, 876, 1072, 1198, 1374, 1520]}
          px={containerPaddingX || null}
          pb={containerPaddingBottom || null}
          height={containerHeight || null}
        >
          {children}
        </Container>
      ) : (
        <Div maxWidth="100%">{children}</Div>
      )}
    </Div>
  </>
);
